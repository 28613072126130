body {
  margin: 20px !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

body::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.versionSelectionInput {
  width: 20%;
}

.dependencyTextFieldDiv {
  margin-bottom: 10px;
  width: 100%;
}

.dependencyTextField {
  width: 50%;
  display: block;
}

.checkmarkField {
  display: block;
}

.tenant-view-button-header {
  display: flex;
  gap: 20px;
  margin-bottom: 10px;
}

.templateSelectInput {
  width: 100%;
}

.templateHeaderContainer {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 15px;
}

.footer {
  position: absolute;
  bottom: 10px;
  padding-right: 10px;
}

.smallFont {
  font-size: 12px;
}

.textFieldDiv {
  display: flex;
  gap: 5px;
  flex-direction: column;
  align-items: flex-start;
  width: 25%;
}

.fullWidth {
  width: 100% !important;
}

.bold {
  font-weight: bold;
}

.underlined {
  text-decoration: underline;
}

.infoPopup {
  animation: 1s ease-out 0s 1 slideInFromLeft;
  position: absolute;
  width: auto;
  max-width: 50%;
  min-width: 20%;
  bottom: 2%;
  left: 2%;
  z-index: 99;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

.dark input, .dark textarea{

  background-color : #141313; 
  color: white;

}

.dark input::placeholder{

  color: rgba(255, 255, 255, 0.534);
}

.dark .dropdown-item, .dark .dropdown-menu {
  background-color: #2e2e2e !important;
  color: white;
}

.dark .dropdown-item:hover {
  background-color: #3e474f !important;
  color: white;
}