:root {
    --navbar-color: #0d6efd
}

.Navbar {
    display: flex;
    /* border-bottom: 2px solid black; */
    width: 100%;
    gap: 10px;
    left: 0px;
  }
  
  .NavbarElement {
    cursor: pointer;
    font-size: large;
    background-color: var(--navbar-color);
    border: 3px solid var(--navbar-color);
    border-bottom: none;
    color: white;
    border-radius: 0.375rem;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    padding: 5px;
  }
  
  .dark .NavbarElement, .dark .NavbarElement:hover {
    color: white !important;
  }
  
  .NavbarElement:hover, .NavbarElementActive {
    background-color: unset;
    color: var(--navbar-color) !important
  }